declare const bootstrap: any;

import type { VisitedPlaces } from "./VisitedPlaces";
import { Sharing } from "./Sharing";
import { UIQuery } from "./UIQuery";

export class ViewerUI {

  protected _visitedPlaces!: VisitedPlaces;
  protected _sharing!: Sharing;

  static new(visitedPlaces: VisitedPlaces): ViewerUI {
    const x = new this(visitedPlaces, true);
    x._afterNew();
    return x;
  }

  constructor(visitedPlaces: VisitedPlaces, isReal: boolean) {
    if (!isReal) {
      throw new Error("You cannot use `new Class()`, instead use `Class.new()`");
    }

    this._visitedPlaces = visitedPlaces;
    this._sharing = Sharing.new(visitedPlaces);
  }

  protected _afterNew() {

    const vp = this._visitedPlaces;

    UIQuery.find("#edit").on("click", () => {
      window.location.href = window.location.href.replace("/view/", "/" + vp.map + "/");
    });
  }

}